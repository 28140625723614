<template>
  <div class="loader">
    <div class="loader-wrapper"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(43, 49, 61, 0.9);
}
.loader-wrapper {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #3498db;
  animation: spin 2s linear infinite;
}
.loader-wrapper::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loader-wrapper::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
