<template>
  <b-row>
    <b-col cols="12">
      <StripChart
        :chartData="stripData"
        @getBreakdown="breakdown"
        @getDataBreakdown="getDataBreakdown"
        :breakdown="breakDown"
        :showBreakdown="showBreakdownIcon"
        :title="title"
        :subtitle="subtitle"
        :total="total"
        :activePart="selectedData.label"
        :dot="dot"
        :showLegend="showLegend"
      />
    </b-col>
    <b-col
      cols="12"
      v-if="breakDown && showBreakdownIcon"
      class="horizontal-breakdownable-card px-0"
      :style="{ '--left': selectedData.value / 2 + valueAdded + '%' }"
    >
      <bar-chart :chartData="barData" />
    </b-col>
  </b-row>
</template>
<script>
import StripChart from "@/components/Chart/StripChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
export default {
  name: "Content",
  beforeUpdate() {
    this.barChartData = this.data;
  },
  created() {
    this.initalCall();
  },
  components: {
    StripChart,
    BarChart,
  },
  props: {
    showLegend:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Content",
    },
    breakeDownShow: {
      type: Boolean,
      default: false,
    },
    showBreakdownIcon:{
      type: Boolean,
      default: true
    },
    barData: {
      type: Object,
      default: () => {},
    },
    stripData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    dot: {
      type: String,
      default: "",
    },
    symbol: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      breakDown: false,
      selectedData: "",
      valueAdded: 0,
    };
  },
  methods: {
    findIndexPosition(data) {
      this.valueAdded = 0;
      let ind = this.stripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.stripData[i].value;
          this.valueAdded += value;
        }
      } else {
        this.valueAdded = 0;
      }
    },
    getDataBreakdown(data) {
      this.selectedData = data.data;
      this.findIndexPosition(data.data);
      this.breakdown(data.breakdown);
      this.$emit("getDataBreakdown", data.data);
    },
    breakdown(newVal) {
      this.breakDown = newVal;
    },
    initalCall() {
      this.barChartData = this.data;
      this.breakDown = this.breakeDownShow;
    },
  },
};
</script>

<style scoped>
.horizontal-breakdownable-card {
  border-top: 2px solid #d4dae4;
  position: relative;
}

.horizontal-breakdownable-card::before {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: -17px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 9999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.horizontal-breakdownable-card::after {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #d4dae4;
  top: -20px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
</style>
