import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class AimoINServices {
    constructor() {}
    getChartData(screenName, instance, graphName, kpiMarket) {
        const uri = `aimo/getGraphdata?screenName=${screenName}&instance=${instance}&graphTitle=${graphName}&kpiMarket=${kpiMarket}`;
        return apiHelper.get(uri);
    }
    getPredictedData(data) {
        const uri = `aimo/load-json?filename=${data.fileName}&growthVal=${data.growthVal}&market=${data.market}`
        return apiHelper.post(uri, data)
    }
    getVariance(growthVal, market) {
        const uri = `aimo/get-variance?growthVal=${growthVal}&market=${market}`
        return apiHelper.get(uri)
    }
    getPredictedThirdLevel(key, market, data, edited, value, readFile) {
        if (value === undefined) {
            value = 0
        }
        const uri = `aimo/getdata?key=${key}&values=&kpiMarket=${market}&edited=${edited}&value=${value}&readFile=${readFile}`
        return apiHelper.post(uri, data);
    }
    getVarianceAfterEdit(revenue, market, growthVal) {
        const uri = `aimo/get-varianceAfterEdtit?revenue=${revenue}&market=${market}&growthValue=${growthVal}`
        return apiHelper.get(uri)
    }
    getAgilePlan(predictedrevenue, predictedbudget, variance) {
        const uri = `aimo/get-agileplan?predictedrevenue=${predictedrevenue}&predictedbudget=${predictedbudget}&variance=${variance}`
        return apiHelper.get(uri)
    }
    getAgileChartData(fileName, market, data) {
        const uri = `aimo/load-json-agile?fileName=${fileName}&kpiMarket=${market}`
        return apiHelper.post(uri, data)
    }
}